<template>
  <div>
    <!-- <v-container v-if="false">
      <v-row>
        <v-col cols="12">
          <v-img class="dark align-center" src="/images/CompleteHitter.png">
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>12 Week Offseason Program - Complete Hitter</h4>
          <p>
            As the saying goes "If you hit, you don't sit". This hitting program
            is designed to help you build your swing into a "Complete Hitter"
            this offseason.
          </p>
          <h4>Step 1: Assessment</h4>
          <p class="mt-2">
            We use this time to evaulate you as a player and more importantly
            learn more about you as a person.
          </p>
          <h4>Step 2: Start your journey to becoming a Complete Hitter</h4>
          <p>We break the offseason into 3 phases:</p>
          <ol>
            <li>Foundation Phase</li>
            <li>"Goal-Oriented" Phase</li>
            <li>Pre-Season Phase</li>
          </ol>
          <h4 class="mt-2">Step 3: Enjoy the Grind</h4>
          <p>
            Bring your best attiude each day, challange yourself and remember to
            have fun!
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <h4>Group Training Format (4-6 per group)</h4>
          <p>Hitters will be paired based off similar:</p>
          <ul>
            <li>Age</li>
            <li>Skill Set</li>
            <li>Goals</li>
          </ul>

          <p class="mt-2">Each hitter will learn how to create their own:</p>
          <ul>
            <li>Hitting Routines</li>
            <li>Indivivual Hitting Approach</li>
            <li>Feels/Hitting Cues</li>
          </ul>
          <p class="mt-2">
            Most importantly each hitter will learn how to be their own coach.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <h4>Perks - Cage Access</h4>
          <p>
            All athletes who are in the "Complete Hitter" will have access to
            the cages 30 min per day when the cages are available!
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <h4>Session Options</h4>
          <p>
            - 3x per week - $720 Total
            <br />
            - 2x per week - $480 Total
            <br />
          </p>
          <v-btn class="primary">Sign Up</v-btn>
        </v-col>
      </v-row>
    </v-container> -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img class="dark align-center" :src="HittingGroups">
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>"The Big 3"</h4>
          <p>
            During each session we will primarly focus on improving 3 hitting
            traits:
          </p>
          <ul>
            <li>Bat Speed</li>
            <li>Barrel Control</li>
            <li>Pitch Recognition</li>
          </ul>

          <p class="mt-2">
            We believe refining these 3 hitting traits will give you the best
            chance at success during the game.
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <h4>Tricks of the Trade:</h4>
          <p>
            We also put emphasis on the mental aspect of the game. Including:
          </p>

          <ul>
            <li>Individual Hitting Routines</li>
            <li>Individual Hitting Approach</li>
            <li>"How to become your own coach"</li>
          </ul>
          <p class="mt-2">
            Athletes in this program will learn all of these concepts and more
            from our experienced coaches.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Perks - Cage Access:</h4>
          <p>
            Daily access to the cages is included with the hitting packages.
            Athletes will receive an additional 30 minutes of cage time
            everyday, Monday-Friday, to continue refining their skills on their
            own time.
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <h4>Pricing</h4>
          <p>
            - $119.00 per month - 1x/week
            <br />
            - $139.00 per month - 2x/week
            <br />
          </p>
          <ContactButton title="Sign Up" />
        </v-col>
      </v-row>
      <v-img class="dark align-center mt-4" :src="PDTrain"></v-img>
    </v-container>
  </div>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import PDTrain from '@/assets/images/PDTrain.svg'
import HittingGroups from '@/assets/images/HittingGroups.svg'


export default {
  components: { ContactButton },
  data() {
    return {
      reveal: false,
      drawer: null,
      PDTrain: PDTrain,
      HittingGroups: HittingGroups,
    }
  },
}
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
